html {
  height: 100%;
}

body {
  font-family: "Titillium Web", sans-serif !important;
  font-size: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.mainComponent {
  height: calc(100% - 64px);
  margin: 0;
  overflow: hidden;
}

.sidebarBackground {
  background: #80808099;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 600;
}

.brow {
  width: 40px;
  height: 4px;
}

.center-on-page {
  text-align: center;
  margin: auto;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%,-50%);
}

.weekcard:hover {
  transform: scale(1.02, 1.02);
}